<template>
  <!-- 报名管理 -->
  <div class="all_main_wrap">
    <HeaderBox>
      <template #title>
        报名管理 / 报名审核
      </template>
      <template #input>
        <a-input v-model="queryParams.sessionName" placeholder="请选择场次名称" allowClear/>
        <a-input v-model="queryParams.name" placeholder="请输入用户名称" allowClear/>
        <a-input v-model="queryParams.mobile" placeholder="请输入手机号" allowClear/>
        <a-button
          type="primary"
          class="btn"
          @click="onSearch"
          :loading="loading"
        >搜索</a-button>
      </template>
    </HeaderBox>

    <a-table 
        class="table-template"
        :rowKey="(item,index)=>index"
        :columns="columns" 
        :loading="loading"
        :data-source="tableData" 
        :locale="{emptyText: '暂无数据'}" 
        @change="onPage"
        :pagination="{
          total:total,
          current:queryParams.pageNum,  
          defaultpageSize:queryParams.pageSize, 
          showSizeChanger: true,
          showTotal: function(total, range){
            return `共${total}条`
          }
        }">
        <!-- 序号 -->
      <template
        slot="index"
        slot-scope="item, row, index"
      >
        {{ (queryParams.pageNum - 1) * queryParams.pageSize + index + 1 }}
      </template>
      <!-- 性别 -->
      <template slot="sex" slot-scope="item">
        <span>{{ item == 0 ? '男' : item == 1 ? '女' : item == 2 ? '未知' :''}}</span>
      </template>
      <!-- 审核状态 -->
      <template slot="auditStatus" slot-scope="item">
        <a-tag :color="item == 1 ? 'orange' : item == 2 ? 'red' : item == 3 ? 'green' : ''">{{ item == 1 ? '待审核' : item == 2 ? '已驳回' : item == 3 ? '报名成功' :''}}</a-tag>
      </template>
      <!-- 操作 -->
      <template slot="operation" slot-scope="item">
        <span class="blueText">
          <span v-if="item.auditStatus == 1" @click="onAuditModal(item)">审核 | </span>
          <span v-else @click="onAuditModal(item)">详情 | </span>
          <span @click="onRemarkModal(item)">备注</span>
        </span>
      </template>
    </a-table>
    <!-- 审核弹窗 -->
    <a-modal v-model="isAuditShow" title="报名信息">
      <div class="all_content_box">
        <a-row class="a-row-item">
          <a-col :span="4" class="a-row-left"
            ><span class="name">场次名称：</span></a-col
          >
          <a-col :span="18">
            <a-input :readOnly="true" v-model="info.sessionName" />
          </a-col>
        </a-row>
        <a-row class="a-row-item">
          <a-col :span="4" class="a-row-left"
            ><span class="name">用户名：</span></a-col
          >
          <a-col :span="18">
            <a-input :readOnly="true" v-model="info.name" />
          </a-col>
        </a-row>
        <a-row class="a-row-item">
          <a-col :span="4" class="a-row-left"
            ><span class="name">手机号：</span></a-col
          >
          <a-col :span="18">
            <a-input :readOnly="true" v-model="info.mobile" />
          </a-col>
        </a-row>
        <a-row class="a-row-item">
          <a-col :span="4" class="a-row-left"
            ><span class="name">邮箱：</span></a-col
          >
          <a-col :span="18">
            <a-input :readOnly="true" v-model="info.email" />
          </a-col>
        </a-row>
        <a-row class="a-row-item">
          <a-col :span="4" class="a-row-left"
            ><span class="name">单位：</span></a-col
          >
          <a-col :span="18">
            <a-input :readOnly="true" v-model="info.unit" />
          </a-col>
        </a-row>
        <a-row class="a-row-item">
          <a-col :span="4" class="a-row-left"
            ><span class="name">职位：</span></a-col
          >
          <a-col :span="18">
            <a-input :readOnly="true" v-model="info.post" />
          </a-col>
        </a-row>
        <a-row class="a-row-item">
          <a-col :span="4" class="a-row-left"
            ><span class="name">联系地址：</span></a-col
          >
          <a-col :span="18">
            <a-input :readOnly="true" v-model="info.contactAddress" />
          </a-col>
        </a-row>
        <a-row class="a-row-item">
          <a-col :span="4" class="a-row-left"
            ><span class="name">报名时间：</span></a-col
          >
          <a-col :span="18">
            <a-input :readOnly="true" v-model="info.signupTime" />
          </a-col>
        </a-row>
        <a-row class="a-row-item" v-if="info.attachments">
          <a-col :span="4" class="a-row-left"
            ><span class="name">用户附件：(点击放大)</span></a-col
          >
          <a-col :span="18">
            <div class="imgs">
              <img class="file" v-for="(item,index) in funImgs(info.attachments)" @click="showUrl(item)" :key="index" :src="(item.endsWith('.heic')||item.endsWith('.HEIC'))?heicIcon:item" alt="">
            </div>
          </a-col>
        </a-row>
        <a-row class="a-row-item" v-if="info.remark">
          <a-col :span="4" class="a-row-left"
            ><span class="name">备注：</span></a-col
          >
          <a-col :span="18" >
            <a-textarea
             :readOnly="true"
              v-model="info.remark"
              :auto-size="{ minRows: 3, maxRows: 15 }"
            />
          </a-col>
        </a-row>
        <a-row class="a-row-item" v-if="info.rejectReason">
          <a-col :span="4" class="a-row-left"
            ><span class="name">驳回原因：</span></a-col
          >
          <a-col :span="18">
            <a-textarea
             :readOnly="true"
              v-model="info.rejectReason"
              :auto-size="{ minRows: 3, maxRows: 15 }"
            />
          </a-col>
        </a-row>
        <a-row class="a-row-item" v-if="info.auditStatus == 1">
          <a-col :span="4" class="a-row-left"
            ><span class="name">审核操作：</span></a-col
          >
          <a-col :span="18">
            <a-radio-group v-model="auditParams.auditStatus">
              <a-radio :value="3">通过报名</a-radio>
              <a-radio :value="2">驳回报名</a-radio>
            </a-radio-group>
          </a-col>
        </a-row>
        <a-row class="a-row-item" v-if="auditParams.auditStatus == 2">
          <a-col :span="4" class="a-row-left"
            ><span class="name">驳回原因：</span></a-col
          >
          <a-col :span="18" >
            <a-textarea
              placeholder="请输入驳回原因，将在用户终端显示。"
              v-model="auditParams.rejectReason"
              :auto-size="{ minRows: 3, maxRows: 5 }"
            />
          </a-col>
        </a-row>
      </div>
      <template slot="footer">
        <a-button type="primary" v-if="info.auditStatus == 1" :loading="loading" @click="onAuditOk"
          >确认</a-button
        >
        <a-button @click="isAuditShow = false">取消</a-button>
      </template>
    </a-modal>
    <!-- 审核弹窗 -->
    <a-modal v-model="isRemarkShow" title="备注信息">
      <div class="all_content_box">
        <a-row class="a-row-item">
          <a-col :span="3" class="a-row-left"
            ><span class="name">备注：</span></a-col
          >
          <a-col :span="18" >
            <a-textarea
              placeholder="请输入用户备注信息。"
              v-model="remark"
              :auto-size="{ minRows: 3, maxRows: 5 }"
            />
          </a-col>
        </a-row>
      </div>
      <template slot="footer">
        <a-button type="primary" :loading="loading" @click="onRemarkOk"
          >确认</a-button
        >
        <a-button @click="isRemarkShow = false">取消</a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import HeaderBox from "@/components/HeaderBox.vue";
import { viewHeic } from '@/unit/fun.js'
import { api as viewerApi } from "v-viewer"

const columns = [
  {
    title: "序号",
    align: "center",
    dataIndex: "index",
    scopedSlots: {
      customRender: "index",
    },
  },
  {
    title: "场次名称",
    align: "center",
    dataIndex: "sessionName",
  },
  {
    title: "用户名",
    align: "center",
    dataIndex: "name",
  },
  {
    title: "性别",
    align: "center",
    dataIndex: "sex",
    scopedSlots: {
      customRender: "sex",
    },
  },
  {
    title: "手机号",
    align: "center",
    dataIndex: "mobile",
  },
  // {
  //   title: "邮箱",
  //   align: "center",
  //   dataIndex: "email",
  // },
  // {
  //   title: "单位",
  //   align: "center",
  //   dataIndex: "unit",
  // },
  {
    title: "报名时间",
    align: "center",
    dataIndex: "signupTime",
  },
  {
    title: "活动开始时间",
    align: "center",
    dataIndex: "activityStartDate",
  },
  {
    title: "签到时间",
    align: "center",
    dataIndex: "verificationTime",
  },
  {
    title: "审核状态",
    align: "center",
    dataIndex: "auditStatus",
    scopedSlots: {
      customRender: "auditStatus",
    },
  },
  {
    title: "审核时间",
    align: "center",
    dataIndex: "auditTime",
  },
  // {
  //   title: "备注",
  //   align: "center",
  //   dataIndex: "remark",
  // },
  {
    title: "操作",
    align: "center",
    width: 130,
    scopedSlots: {
      customRender: "operation",
    },
  },
];
export default {
  // 可用组件的哈希表
  components: {HeaderBox},
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      columns,
      heicIcon: require('@/assets/image/subjective/icon-heic.png'),
      loading:false,
      tableData:[],
      total:0,
      info:{},
      queryParams: {
        activityId: 0,
        sessionName:'', // 场次名称
        name:'',//用户名称
        mobile:'',//手机号
        pageNum: 1, //页码
        pageSize: 10, // 页数
      },
      auditParams: {
        id: 0,
        auditStatus: 3,
        rejectReason: '',
      },
      isAuditShow: false,
      isRemarkShow: false,
      remark:'',
    }
  },
  // 事件处理器
  methods: {
    // 预览文件
    showUrl(url) {
      if(url.endsWith('.heic') || url.endsWith('.HEIC')){
        this.$message.loading({ content: "HEIC图片在线预览解析中...", duration: 0 });
        viewHeic(url).then((res)=>{
          window.open(res);
          this.$message.success();
        })
      } else {
        var array = [url];
        const $viewer = viewerApi({
          options: {
            toolbar: true,
            initialViewIndex: 0
          },
          images: array
        })
      }
    },

    funImgs(url){
      return url.split(',');
    },

    // 分页
    onPage(e){
      this.queryParams.pageNum = e.current
      this.queryParams.pageSize = e.pageSize
      this.getList()
    },

    onSearch() {
      this.queryParams.pageNum = 1;
      this.getList();
    },

    // 查询报名列表
    getList() {
      this.loading = true
      this.$ajax({
        url: "/hxclass-management/activitySignup/manage/list",
        params: this.queryParams,
      }).then((res) => {
        this.loading = false
        if (res.code == 200 && res.success) {
          this.total = res.data.total;
          this.tableData = res.data.records;
        }
      });
    },
    // 审核弹窗唤起
    onAuditModal(e){
      this.info = e
      this.info.sessionName = e.activityName + ' | ' + e.sessionName
      this.auditParams.id = e.id
      this.auditParams.auditStatus = 3
      this.auditParams.rejectReason = ''
      this.isAuditShow = true
    },
    // 审核确认
    onAuditOk(){
      this.loading = true
      if(this.auditParams.auditStatus == 3){
        this.auditParams.rejectReason = ''
      }
      this.$ajax({
        url: "/hxclass-management/activitySignup/manage/signUp/audit",
        method: "post",
        params: this.auditParams
      })
      .then((res) => {
        this.loading = false
        if (res.code == 200 && res.success) {
          this.$message.success("操作成功");
          this.isAuditShow = false
          this.getList();
        }
      });
    },
    // 备注弹窗唤起
    onRemarkModal(e){
      this.auditParams.id = e.id
      this.remark = e.remark
      this.isRemarkShow = true
    },
    // 添加备注
    onRemarkOk(){
      if(!this.remark){
        return this.$message.warning("请输入备注信息！");
      }
      this.loading = true
      this.$ajax({
        url: "/hxclass-management/activitySignup/manage/signUp/audit",
        method: "post",
        params: {
          id: this.auditParams.id,
          remark: this.remark,
        }
      })
      .then((res) => {
        this.loading = false
        if (res.code == 200 && res.success) {
          this.$message.success("操作成功");
          this.isRemarkShow = false
          this.getList();
        }
      });
    },
  },
  // 生命周期-实例创建完成后调用
  created () { 
    this.queryParams.activityId = this.$route.query.activityId;
  },
  // 生命周期-实例挂载后调用
  mounted () {
    this.getList()
  },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>

<style lang="less" scoped>
.btn {
  margin: 0 24px 14px 0;
}
.all_content_box {
  .a-row-item {
    margin-bottom: 20px;
    .a-row-left {
      margin-right: 10px;
    }
    .name {
      width: 100%;
      display: inline-block;
      text-align: right;
    }
    .right {
      width: 100%;
    }
  }
  .tips {
    font-size: 17px;
    color: #333;
    margin: 0 0 20px;
  }
  .code-tips {
    font-size: 17px;
    color: #333;
    p{
      margin-bottom: 5px;
    }
    .up{
      margin-top: 20px;
    }
  }
}
.imgs{
  display: flex;
  flex-wrap: wrap;
  .file{
    margin-right: 20px;
    width: calc((354px - 40px) / 3);
    height: 100px;
    margin-bottom: 20px;
    cursor: pointer;
    &:nth-child(3n){
      margin-right: 0;
    }
  }
}
</style>
